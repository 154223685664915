import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TablePagination, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, IconButton, Button} from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";


import { GetLocalStorage, SetLocalStorage, Sort, DownloadFile } from '.././Utils';
import { DateTime } from "luxon";
import { isPaidInvoice, isUnPaidInvoice, sendEmailInvoice } from '.././Store';

import CreateInvoice from './CreateInvoice';

function Invoices(data) {

	const { search, getRemoteConf } = useContext(ShakiContext);
	const { user, checkUserRights } = useContext(UsersContext);
	const { shops, deleteInvoice } = useContext(ShopsContext);

	var { shop_id } = useParams();
	var { include, shop } = data;

	const title = 'Factures';

	const classes = useStyles();

	const [invoices, setInvoices] = useState(null);
	const [list, setList] = useState([]);
	const [total, setTotal] = useState({
		ht : 0,
		ttc : 0,
		factor_ht : 0,
		factor_ttc : 0
	});

	const [invoice_generating, setInvoiceGenerating] = useState(false);

	const [filter, setFilter] = useState(parseInt(GetLocalStorage('invoice_filter', 0)));

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [open_dialog, setOpenDialog] = useState(false);
	const [dialog_date, setDialogDate] = useState(DateTime.local());
	const [dialog_invoice, setDialogInvoice] = useState({});

	const [dialog_new_invoice_opened, setDialogNewInvoiceOpened] = useState(false);
	const [tags, setTags] = useState([]);


	useEffect(() => {
		setTags(getRemoteConf('tags'));
	}, []);


	useEffect(() => {

		var treatment = (querySnapshot) => {

			if (querySnapshot === null) return;

			var list = [];
			querySnapshot.forEach(doc => {
				var data = doc.data();
				data.order_key = DateTime.fromISO(data.date).toFormat('yyyy') + '-' + String(data.number).padStart(5, '0');
				list.push(data);
			});

			list.sort((a, b) => Sort(a, b, 'date', 'desc'))

			setInvoices(list);
		}

		if (shop_id === 'all') {
			return firebase.firestore().collection('invoices').onSnapshot(treatment);
		} else {
			return firebase.firestore().collection('invoices').where('shop_id', '==', shop.id).onSnapshot(treatment);
		}

	}, []);


	useEffect(() => {

		if (shop_id === null) return;

		return firebase.firestore().collection('invoices_queue').doc(shop_id).onSnapshot((doc) => {
			setInvoiceGenerating(doc.exists);
		});

	}, [shop_id]);


	useEffect(() => {

		if (tags.length === 0) return;
		if (invoices === null) return;
		if (shops === null) return;

		var list = invoices.filter((row) => {

			if (filter === 1 && row.emailed.length !== 0) return false;
			if (filter === 2 && row.emailed.length === 0) return false;
			if (filter === 3 && (row.paid !== undefined && row.paid !== false)) return false;
			if (filter === 4 && (row.paid === undefined || row.paid === false)) return false;

			if (shops[row.shop_id].tags !== undefined) {
				for (var i in shops[row.shop_id].tags) {
					if (tags.find((element) => element.key === shops[row.shop_id].tags[i]).title.toLowerCase().search(search.toLowerCase()) !== -1) return true;
				};
			};

			return (search === ''
				|| shops[row.shop_id].name.toLowerCase().search(search) !== -1
				|| shops[row.shop_id].key.search(search) !== -1
				|| (shops[row.shop_id].contact_accounting !== undefined && shops[row.shop_id].contact_accounting.legal_entity !== undefined && shops[row.shop_id].contact_accounting.legal_entity.toLowerCase().search(search) !== -1)
				|| row.order_key.search(search) !== -1)
		});

		var total = {
			ht: 0,
			ttc: 0,
			factor_ht: 0,
			factor_ttc: 0
		};

		for (var i in list) {

			var invoice = list[i];
			if (invoice.deleted) continue;

			if (invoice.factoring !== undefined && invoice.factoring === true) {
				total.factor_ht += invoice.total_ht;
				total.factor_ttc += isNaN(invoice.total_ttc) ? 0 : invoice.total_ttc;
			} else {
				total.ht += isNaN(invoice.total_ht) ? 0 : invoice.total_ht;
				total.ttc += isNaN(invoice.total_ttc) ? 0 : invoice.total_ttc;
			}
		}

		setTotal(total);
		setList(list.sort((a, b) => { return a.order_key < b.order_key ? 1 : -1 }));

	}, [invoices, filter, shops, search, tags]);


	const sendEmail = (invoice_id) => {
		sendEmailInvoice(invoice_id, user);
	};


	const isPaid = (invoice_id, date) => {
		isPaidInvoice(invoice_id, date, user);
	};


	const isUnPaid = (invoice_id) => {
		isUnPaidInvoice(invoice_id, user);
	};


	const switchFilter = (filter) => {
		setFilter(filter);
		setPage(0);
		SetLocalStorage('invoice_filter', filter);
	};


	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};


	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};


	const handleClose = (value) => {
		setDialogNewInvoiceOpened(false);
	};

	return (
		<div>

			<CreateInvoice shop_id={shop_id} open={dialog_new_invoice_opened} onClose={handleClose}/>

			<div style={{ padding: 20 }}>

				{!include &&
					<>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to='/'>Dashboard</Link>
							<Typography>{title}</Typography>
						</Breadcrumbs>
						<h2>{title}</h2>
					</>
				}

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div style={{display : 'flex', alignItems : 'center'}}>
						{shop_id === 'all' &&
							<div>
								<div><b>{list.length} factures</b> </div>
								<div style={{ width: 400 }}>
									<div style={{ display : 'flex', flexDirection: 'row' }}>
										<div style={{ width: '20%' }}>Standard</div>
										<div style={{ width: '40%', textAlign: 'right' }}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total.ht)} HT</div>
										<div style={{ width: '40%', textAlign: 'right' }}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total.ttc)} TTC</div>
									</div>
									<div style={{ display : 'flex', flexDirection: 'row' }}>
										<div style={{ width: '20%' }}>Factor</div>
										<div style={{ width: '40%', textAlign: 'right' }}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total.factor_ht)} HT</div>
										<div style={{ width: '40%', textAlign: 'right' }}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(total.factor_ttc)} TTC</div>
									</div>
								</div>
							</div>
						}
					</div>

					<div style={{ display : 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

						{
						['Toutes', 'En attente d\'envoi', 'Envoyées', 'En attente de paiement', 'Payées'].map((action, i) => (
							<div key={'filter_' + i} style={filter === i ? { padding: 5, backgroundColor: '#000', color: '#FFF', borderRadius: 5, marginLeft: 5 } : { padding: 5, backgroundColor: '#F0F0F0', color: '#000', borderRadius: 5, marginLeft: 5 }} onClick={(e) => switchFilter(i)}>
								{action}
							</div>
						))
						}


						{(shop_id !== 'all') &&
						<Button onClick={(e) => {setDialogNewInvoiceOpened(true); return true;}} variant='contained' color='primary' size="small" style={{ marginLeft: 10 }}>
							Créer une facture
						</Button>
						}

					</div>
				</div>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell style={{ width: '12%' }} align="right">N°</StyledTableCell>
							<StyledTableCell style={{ width: '10%' }}>ID</StyledTableCell>
							<StyledTableCell style={{ width: '18%' }}>Client</StyledTableCell>
							<StyledTableCell style={{ width: '10%' }}>Période / Commande</StyledTableCell>
							<StyledTableCell style={{ width: '8%' }}>Emise le</StyledTableCell>
							<StyledTableCell style={{ width: '7%' }} align="right">Total HT</StyledTableCell>
							<StyledTableCell style={{ width: '10%' }} align="right">Total TTC</StyledTableCell>
							<StyledTableCell style={{ width: '10%' }}>Envoi</StyledTableCell>
							<StyledTableCell style={{ width: '10%' }}>Paiement</StyledTableCell>
							<StyledTableCell style={{ width: '5%' }}>Action</StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (

						<StyledTableRow key={row.key} style={{ textDecorationLine: row.deleted ? 'line-through' : 'none' }}>
							<StyledTableCell component="th" scope="row" align="right">{row.order_key}</StyledTableCell>
							{user.admin &&
								<>
								<StyledTableCell>{shops[row.shop_id].key}</StyledTableCell>
								<StyledTableCell>
									<Link to={'/shops/' + row.shop_id}>{shops[row.shop_id].name}</Link>
								</StyledTableCell>
								</>
							}
							<StyledTableCell>
								{(row.type === 'invoice_month' || row.type === 'invoice_revshare') &&
									DateTime.fromISO(row.year + '' + String(row.month).padStart(2, '0')).setLocale('fr').toFormat('LLLL yyyy')
								}

								{(row.type === 'invoice_order') &&
									 <Link to={'/order/' + row.key}>Commande {row.key}</Link>
								}

								{(row.type === 'invoice_credit' || row.type === 'credit') &&
									<>Avoir</>
								}

								{(row.type === 'invoice') &&
									<>Facture divers</>
								}
							</StyledTableCell>

							<StyledTableCell>{DateTime.fromISO(row.date).setLocale('fr').toFormat('dd/LL/yyyy')}</StyledTableCell>

							{/* ttc column */}
							{(row.type === 'invoice_credit' || row.type === 'credit') ?
								<StyledTableCell align="right">-{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ht)}</StyledTableCell>
							:
								<StyledTableCell align="right">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ht)}</StyledTableCell>
							}

							{(row.total_ttc_before_credit !== undefined && row.total_ttc_before_credit !== row.total_ttc) &&
								<StyledTableCell align="right">
									<span style={{ textDecorationLine: 'line-through', fontSize: 12 }}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ttc_before_credit)}</span><br/>
									{row.credit_invoices.map((credit_invoices) => (
										<span title={credit_invoices.invoice_credit_simplified_id} style={{ fontSize: 12 }}>-{credit_invoices.amount} €<br/></span>
									))}
									{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ttc)}
								</StyledTableCell>
							}

							{(row.type !== 'invoice_credit' && row.type !== 'credit' && (row.total_ttc_before_credit === undefined || row.total_ttc_before_credit === row.total_ttc)) &&
								<StyledTableCell align="right">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ttc)}</StyledTableCell>
							}

							{row.type === 'credit' &&
								<StyledTableCell align="right">-{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ttc)}</StyledTableCell>
							}

							{(row.type === 'invoice_credit' && row.total_ttc !== row.remaining_credit) &&
								<StyledTableCell align="right">
									<span style={{ textDecorationLine: 'line-through', fontSize: 12 }}>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ttc)}</span><br/>
									{row.history.map((credit_invoices) => (
										<span title={credit_invoices.invoice_credited_simplified_id} style={{ fontSize: 12 }}>-{credit_invoices.amount} €<br/></span>
									))}
									{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.remaining_credit)}
								</StyledTableCell>
							}

							{(row.type === 'invoice_credit' && row.total_ttc === row.remaining_credit) &&
								<StyledTableCell align="right">-{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.total_ttc)}</StyledTableCell>
							}

							{user.admin &&
							<StyledTableCell>
								{row.emailed.length === 0 ?
								<Button disabled={!checkUserRights('invoices_send')} onClick={(e) => sendEmail(row.id)} variant="outlined" size="small">
									Envoyer
								</Button>
								:
								<>
								<div style={{ fontSize: 12 }}>envoyé le {DateTime.fromSeconds(row.emailed[row.emailed.length - 1]).toFormat('dd/LL/yyyy')}</div>
								{(row.paid === false || row.paid === undefined) &&
								<Button disabled={!checkUserRights('invoices_send')} onClick={(e) => sendEmail(row.id)} variant="outlined" size="small">
									Renvoyer({row.emailed.length})
								</Button>
								}
								</>
								}
							</StyledTableCell>
							}


							<StyledTableCell>
								{(user.admin) &&
									<>
									{row.paid === false || row.paid === undefined ?
										<Button disabled={!checkUserRights('invoices_send')} onClick={(e) => { setDialogInvoice(row); setOpenDialog(true);}} variant="outlined" size="small">
											{row.type !== 'invoice_credit' ? 'Reçu' : 'Appliquée'}
										</Button>
									:
									<div style={{ fontSize: 12 }}>reçu le <a href='#' onClick={(e) => {
										if (dialog_invoice.paid !== undefined && dialog_invoice.paid !== false) setDialogDate(DateTime.fromSeconds(dialog_invoice.paid))
										setDialogInvoice(row);
										setOpenDialog(true);
									}}>
										{DateTime.fromSeconds(row.paid).toFormat('dd/LL/yyyy')}
									</a></div>
									}
									</>
								}
							</StyledTableCell>


							{user.admin &&
							<StyledTableCell>

								<IconButton onClick={(e) => DownloadFile(row.path)} variant="outlined" size="small">
									<GetAppIcon />
								</IconButton>

								{row.deleted === undefined &&
								<IconButton disabled={!checkUserRights('invoices_delete')} onClick={(e) => {deleteInvoice(row.id); return true;}} variant="outlined" size="small">
									<DeleteOutlineIcon />
								</IconButton>
								}

							</StyledTableCell>
							}

						</StyledTableRow>
						))}
					</TableBody>
				</Table>

			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[50, 100, 200]}
				component="div"
				count={list.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

			<Dialog
				open={open_dialog}
				onClose={() => {
					setOpenDialog(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Réception paiement {dialog_invoice.order_key}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<MuiPickersUtilsProvider utils={LuxonUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant='static'
							format='d MMMM yyyy'
							margin='normal'
							label='Jour de livraison'
							value={dialog_date}
							onChange={(date) => {setDialogDate(date)}}
							shouldDisableDate={(date) => {}}
							disablePast={false}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
						/>
						</MuiPickersUtilsProvider>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						setOpenDialog(false);
						return isUnPaid(dialog_invoice.id);
					}} color="primary" autoFocus>
						Supprimer
					</Button>
					<Button onClick={() => {
						setOpenDialog(false);
						return isPaid(dialog_invoice.id, dialog_date);
					}} color="primary" autoFocus>
						Confirmer
					</Button>
				</DialogActions>
			</Dialog>

		</div>
	);
}

export default React.memo(Invoices);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
});
