import React from 'react';
import { useState, useEffect, useContext } from 'react';
import firebase from "firebase/compat/app";

import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TableCell, Checkbox, TableRow, TextField, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import Resizer from 'react-image-file-resizer';
import { useForm, Controller, useFieldArray } from "react-hook-form";

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { ShakiContext } from "../store/ShakiProvider";
import { UsersContext } from "../store/UsersProvider";

import { UploadImage, GetImagePath, getGtin14 } from '.././Utils';
import { UpdateProduct } from '.././Store';


function Product() {

	const { search, setOpenSnackBar, products, GetProduct, getRemoteConf } = useContext(ShakiContext);
	const { user, checkUserRights } = useContext(UsersContext);

	var { product_id } = useParams();

	const classes = useStyles();

	var requirements_options = ['Végétarien', 'Végan'];
	const [current_product, setCurrentProduct] = useState({});
	const [packaging_types, setPackagingTypes] = useState({});
	const [production_lines, setProductionLines] = useState({});


	const { register, handleSubmit, setValue, errors, control, reset } = useForm({
		defaultValues: {
			packaging: {
			},
			requirements: []
		}
	});


	const { fields: fields_checklist, append: append_checklist, remove: remove_checklist } = useFieldArray({
		control,
		name: 'checklist'
	});


	useEffect(() => {
		setPackagingTypes(getRemoteConf('packaging_types'));
		setProductionLines(getRemoteConf('production_lines'));
	}, []);


	useEffect(() => {

		GetProduct(product_id, (doc) => {
			var data = doc.data();
			data.id = doc.id;
			reset(data);
			setCurrentProduct(data);
		});

	}, [product_id]);


	const getNextPictureId = () => {

		if (current_product.images === undefined) return 0;

		var last = 0;
		var a = Object.keys(current_product.images).sort();
		for (var i in a) {
			var value = parseInt(a[i]);
			if (isNaN(value)) continue;
			last = value + 1;
		}

		return last;
	};


	const fileChangedHandler = (event) => {

		var fileInput = false
		if (event.target.files[0]) {
			fileInput = true
		}

		if (fileInput) {
			Resizer.imageFileResizer(event.target.files[0], 1200, 1200, 'JPEG', 90, 0, (file) => {
				UploadImage('products', event.target.id, current_product.id, file, (path) => {
					setOpenSnackBar('Sauvegardé')
				});
			}, 'file');
		};
	};


	const onSubmit = (raw_data) => {

		var data = JSON.parse(JSON.stringify(raw_data));

		if (data.production.conveyor_speed !== '') {
			data.production.conveyor_speed = parseInt(data.production.conveyor_speed);
		} else {
			delete data.production.conveyor_speed;
		};

		if (data.production.nb_person !== '') {
			data.production.nb_person = parseInt(data.production.nb_person);
		} else {
			delete data.production.nb_person;
		};

		if (data.production.rate !== '') {
			data.production.rate = parseInt(data.production.rate);
		} else {
			delete data.production.rate;
		};

		if (data.production.line !== '0') {
			data.production.line = parseInt(data.production.line);
		} else {
			delete data.production.line;
		};

		if (data.production.order !== '0') {
			data.production.order = parseInt(data.production.order);
		} else {
			delete data.production.order;
		};

		if (Object.values(data.production).length === 0) data.production = firebase.firestore.FieldValue.delete();

		if (data.packaging !== undefined && data.packaging.heat_up !== undefined && data.packaging.heat_up !== '') {
			data.packaging.heat_up = parseInt(data.packaging.heat_up);
		} else {
			delete data.packaging.heat_up
		};

		if (data.packaging !== undefined && data.packaging.pcb !== undefined) data.packaging.pcb = parseInt(data.packaging.pcb);


		if (data.requirements.length === 0) data.requirements = firebase.firestore.FieldValue.delete();


		if (data.organoleptics.taste === '') delete data.organoleptics.taste;
		if (data.organoleptics.appearance === '') delete data.organoleptics.appearance;
		if (data.organoleptics.color === '') delete data.organoleptics.color;
		if (data.organoleptics.smell === '') delete data.organoleptics.smell;
		if (Object.values(data.organoleptics).length === 0) data.organoleptics = firebase.firestore.FieldValue.delete();

		delete data.parent_sku;
		delete data.sku;
		if (current_product.parent_sku === undefined) {
			delete data.name;
		};

		//console.log(data);

		UpdateProduct(current_product.id, data, user, () => {
			setOpenSnackBar(true);
		});
	};


	console.log(current_product)


	return (
		<div style={{ padding: 20 }}>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Accueil</Link>
				<Link to='/products'>Produits</Link>
				<Typography>{current_product.name}</Typography>
			</Breadcrumbs>

			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 40 }}>
				<div>
					<h2>{current_product.name}</h2>
					<div>{current_product.brand} / {current_product.category}</div>
				</div>
			</div>


			<div style={{ width: 700 }}>

				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ marginBottom: 85 }}>

						<div style={{ marginBottom: 40 }}>

							<div style={{ display: 'flex', marginBottom: 10 }}>

								{current_product.parent_sku !== undefined &&
								<TextField
									disabled={true}
									label='Parent SKU'
									InputLabelProps={{ shrink: true }}
									value={current_product.parent_sku}
									style={{ width: 100, marginRight: 10 }}
								/>
								}

								<TextField
									required
									disabled={true}
									InputLabelProps={{ shrink: true }}
									inputRef={register({
										required : true,
									})}
									id='sku'
									name='sku'
									label='SKU'
									style={{ width: 100 }}
									error={errors.sku ? true : false}
								/>

								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									id='ean13'
									name='ean13'
									label='EAN13'
									style={{ width: 150, marginLeft: 10 }}
								/>

								<TextField
									disabled={true}
									InputLabelProps={{ shrink: true }}
									style={{ width: 150, marginLeft: 10 }}
									label='GTIN14'
									value={getGtin14(current_product.ean13)}
								/>
							</div>

							{current_product.parent_name !== undefined &&
								<div style={{display: 'flex', marginBottom: 15}}>
									<TextField
										disabled={true}
										InputLabelProps={{ shrink: true }}
										id='parent_name'
										name='parent_name'
										label='Nom parent'
										value={current_product.parent_name}
										style={{ width: '100%' }}
									/>
								</div>
							}

							<div style={{display: 'flex', marginBottom: 15}}>
								<TextField
									required
									disabled={current_product.parent_sku === undefined}
									InputLabelProps={{ shrink: true }}
									inputRef={register({
										required : true
									})}
									id='name'
									name='name'
									label='Nom'
									style={{ width: '100%' }}
									error={errors.name ? true : false}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15}}>
								<TextField
									InputLabelProps={{ shrink: true }}
									inputRef={register()}
									name='locales.en.name'
									label='Nom 🇬🇧'
									style={{ marginLeft: 20, width: '100%' }}
									error={errors.name ? true : false}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='descriptive_name'
									label='Nom descriptif'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='description'
									label='Description'
									multiline
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									InputLabelProps={{ shrink: true }}
									inputRef={register()}
									name='locales.en.description'
									label='Description 🇬🇧'
									style={{ marginLeft: 20, width: '100%' }}
									error={errors.name ? true : false}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='extra_info'
									label='Extra'
									style={{ width: '100%' }}
								/>
							</div>

						</div>

						<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ paddingBottom: 20 }}><b>Médias :</b></Typography>

							<div>
								<label htmlFor='main' style={{ textAlign: 'center' }}>
									<input id='main' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'main')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										P
									</Avatar>
									Packshot
								</label>

								<label htmlFor='ambiance' style={{ marginLeft: 20, textAlign: 'center' }}>
									<input id='ambiance' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'ambiance')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										A
									</Avatar>
									Ambiance
								</label>
							</div>

							<div>
								<label htmlFor='label_reference' style={{ textAlign: 'center' }}>
									<input id='label_reference' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'label_reference')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										E
									</Avatar>
									Reférence étiquette
								</label>

								<label htmlFor='packaging_reference' style={{ marginLeft: 20, textAlign: 'center' }}>
									<input id='packaging_reference' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'packaging_reference')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										P
									</Avatar>
									Réference packaging
								</label>
							</div>

						</div>



						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{paddingBottom: 20}}><b>Packaging / Impression :</b></Typography>

							{Object.values(packaging_types).length > 0 &&
							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '50%' }}>
									<InputLabel htmlFor="packaging_type">Conditionnement</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.type',
											id: 'packaging_type',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value=''>---</option>
										{Object.entries(packaging_types).sort((a, b) => { return a[1].name < b[1].name? -1 : 1; }).map((row, index) => (
											<option value={row[0]}>{row[1].name}</option>
										))}
									</Select>
								</FormControl>
							</div>
							}

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '50%' }}>
									<InputLabel htmlFor="packaging_type">PCB de</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.pcb',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='1'>1</option>
										<option value='2'>2</option>
										<option value='3'>3</option>
										<option value='4'>4</option>
										<option value='5'>5</option>
										<option value='6'>6</option>
										<option value='7'>7</option>
										<option value='8'>8</option>
									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '50%' }}>
									<InputLabel htmlFor="packaging_format">Format étiquette</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.format',
											id: 'packaging_format',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='none'>---</option>
										<option value='daifuku'>Daifuku</option>
										<option value='luna_135_60'>Luna 135x60</option>
										<option value='onigiri'>Onigiri</option>
										<option value='rectangle_108_50'>Rectangle 108x50</option>
										<option value='roll'>Roll</option>
										<option value='round_60'>Rond diam 60</option>
									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '50%' }}>
									<InputLabel htmlFor="packaging_format">Format étiquette arrière</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.back_format',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='none'>---</option>
										<option value='onigiri'>Onigiri</option>
										<option value='foodles'>Foodles</option>
										<option value='onigiri_monoprix'>Onigiri Monoprix</option>
										<option value='onigiri_grandfrais'>Onigiri Grand Frais</option>
										<option value='onigiri_rond'>Onigiri Rond</option>
										<option value='square_60'>Carré 60x60</option>
										<option value='square_60_barcode'>Carré 60x60 avec code barre</option>
										<option value='55_80'>Rectangle 55x80</option>
										<option value='55_80_carrefour'>Rectangle 55x80 Carrefour</option>
										<option value='55_80_barcode'>Rectangle 55x80 avec code barre</option>
										<option value='50_100_barcode'>Rectangle 50x100 avec code barre</option>
										<option value='rectangle_108_50'>Rectangle 108x50</option>
									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '50%' }}>
									<InputLabel htmlFor="packaging_triman">Triman</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.triman',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='none'>---</option>
										<option value='triman_bowl'>Bol</option>
										<option value='triman_bowl_cup'>Bol + coupelle sauce</option>
										<option value='triman_flowpack'>Flowpack</option>
										<option value='triman_flowpack_kraft'>Flowpack + étui kraft</option>
										<option value='triman_onigiri'>Onigiri</option>
										<option value='triman_club'>Club</option>
									</Select>
								</FormControl>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									InputProps={{
										endAdornment : <InputAdornment position="end">s</InputAdornment>,
									}}
									name='packaging.heat_up'
									label='Temps de chauffe'
									style={{ width: 150 }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>

								<TextField
									inputRef={register()}
									name='packaging.name_line_1'
									InputLabelProps={{ shrink: true }}
									label='Ligne 1'
									style={{ width: '50%' }}
								/>

								<TextField
									inputRef={register()}
									name='packaging.name_line_2'
									InputLabelProps={{ shrink: true }}
									label='Ligne 2'
									style={{ width: '50%', marginLeft: 15 }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>

								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='packaging.original_version'
									label='Version originale'
									style={{ width: '50%' }}
								/>

							</div>

							<div style={{ marginBottom : 15 }}>

								<Controller
									defaultValue={false}
									name='packaging.spicy'
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
										label='Spicy'
										style={{ width: 170 }}
										/>
									}
								/>

							</div>

						</div>


						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ marginBottom: 20 }}>
								<b>Production :</b>
							</Typography>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: 300 }}>
									<InputLabel htmlFor="production_line">Numéro de ligne</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'production.line'
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='0'>-------</option>

										{Object.entries(production_lines).sort((a, b) => { return a[1].index < b[1].index ? -1 : 1; }).map((row, index) => (
											<option value={row[1].index}>{row[1].name}</option>
										))}

									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: 300 }}>
									<InputLabel htmlFor="production_order">Ordre de passage sur la ligne</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'production.order'
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='0'></option>
										<option value='1'>1</option>
										<option value='2'>2</option>
										<option value='3'>3</option>
										<option value='4'>4</option>
										<option value='5'>5</option>
										<option value='7'>6</option>
										<option value='7'>7</option>
										<option value='8'>8</option>
										<option value='9'>9</option>
										<option value='10'>10</option>
										<option value='11'>11</option>
										<option value='12'>12</option>
										<option value='13'>13</option>
										<option value='14'>14</option>
										<option value='15'>15</option>
										<option value='16'>16</option>
										<option value='17'>17</option>
										<option value='18'>18</option>
										<option value='19'>19</option>
										<option value='20'>20</option>
										<option value='21'>21</option>
										<option value='22'>22</option>
										<option value='23'>23</option>
										<option value='24'>24</option>
										<option value='25'>25</option>
										<option value='26'>26</option>
										<option value='27'>27</option>
										<option value='28'>28</option>
										<option value='29'>29</option>
										<option value='30'>30</option>
									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
									InputLabelProps={{ shrink: true }}
									name='production.nb_person'
									label='Nombre de personne sur la ligne'
									style={{ width: 300 }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
									InputLabelProps={{ shrink: true }}
									name='production.rate'
									label="UVC / heure"
									style={{ width: 300 }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
									InputLabelProps={{ shrink: true }}
									name='production.conveyor_speed'
									label="Vitesse du convoyeur"
									style={{ width: 300 }}
								/>
							</div>

						</div>

						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ marginBottom: 20 }}>
								<b>R&D :</b>
							</Typography>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='origin'
									label='Origine et espèce'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>

								<Controller
									render={props => (
										<Autocomplete
											{...props}

											style={{ width: '100%',  marginTop: 15 }}
											multiple
											id='requirements-standard'
											options={requirements_options}
											getOptionLabel={(option) => option}
											getOptionSelected={(option, value) => option === value}

											onChange={(_, data) => props.onChange(data)}

											filterSelectedOptions={true}

											renderInput={(params) => (
												<TextField
													{...params}
													variant='standard'
													label=''
													placeholder="Exigences alimentaires"
												/>
											)}
										/>
									)}
									defaultValue={[]}
									name='requirements'
									control={control}
								/>

							</div>
						</div>

						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ marginBottom: 20 }}>
								<b>Caractéristiques organoleptiques :</b>
							</Typography>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.appearance'
									label='Aspect'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.color'
									label='Couleur'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.taste'
									label='Goût'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.smell'
									label='Odeur'
									style={{ width: '100%' }}
								/>
							</div>
						</div>


						{current_product.nutrition !== undefined &&
						<div style={{ width: 600, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

							{current_product.nutrition.total_kcal !== undefined &&
							<div style={{ marginBottom: 20 }}>
								<Typography variant='h6' noWrap style={{paddingBottom: 5}}><b>Total Kcal</b></Typography>
								{current_product.nutrition.total_kcal}kcal
							</div>
							}

							<div style={{ marginBottom: 20 }}>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Poids</b></Typography>
								{current_product.weight}g
							</div>

							<div style={{ marginBottom: 20 }}>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>DLC</b></Typography>
								{current_product.dlc_days} jour(s)
							</div>

							<div style={{ marginBottom: 20 }}>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Coût de production</b></Typography>
								{new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(current_product.production_cost)}
							</div>

						</div>
						}

						<div style={{ marginBottom: 20 }}>
							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Ingrédients</b></Typography>
							{current_product.ingredients}
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								inputRef={register()}
								InputLabelProps={{ shrink: true }}
								name='simplified_ingredients'
								label="Liste d'ingrédients simplifiée"
								multiline
								style={{ width: '100%' }}
							/>
						</div>

						{current_product.allergens !== undefined &&
						<div style={{ marginBottom: 20 }}>
							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Allergènes</b></Typography>
							{current_product.allergens.join(', ')}
						</div>
						}

						{current_product.nutrition !== undefined &&
						<div style={{ }}>
							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Valeurs nutritionnelles</b></Typography>
							<p style={{ marginBottom: 0 }}>Energie : {current_product.nutrition.kcal}kcal / {current_product.nutrition.kj}kj</p>
							<p style={{ marginBottom: 0 }}>Protéines : {current_product.nutrition.proteins}g</p>
							<p style={{ marginBottom: 0 }}>Lipides : {current_product.nutrition.fat}g</p>
							<p style={{ marginBottom: 0 }}>Dont graisses saturées : {current_product.nutrition.saturated_fat}g</p>
							<p style={{ marginBottom: 0 }}>Glucides : {current_product.nutrition.carbs}g</p>
							<p style={{ marginBottom: 0 }}>Dont sucre : {current_product.nutrition.sugar}g</p>
							<p style={{ marginBottom: 0 }}>Sel : {current_product.nutrition.salt}g</p>
						</div>
						}

					</div>

					<div style={{ position: 'fixed', bottom: 0, left: 200, width: '100%', backgroundColor: 'white', padding: 24, borderTop: 'solid rgba(0, 0, 0, 0.12) 1px' }}>
						<Button disabled={!checkUserRights('products_write')} variant='contained' color='primary' type='submit'>
							Enregistrer
						</Button>
					</div>

				</form>
			</div>
		</div>
	);
}

export default React.memo(Product);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	}
});
