import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { Checkbox, TextField, Typography, Breadcrumbs, Button, FormControl, Select, FormControlLabel, InputLabel, InputAdornment, OutlinedInput, MenuItem, ListItemText } from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";


import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from "luxon";

import PriceForm from './PriceForm';

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";

import { Sort } from '.././Utils';




function Price(data) {

	const { checkUserRights } = useContext(UsersContext);
	const { price_list, price_list_groups, GetShopsByPricelist, GetCurrentRateCard } = useContext(ShopsContext);
	const { products_by_sku, setOpenSnackBar } = useContext(ShakiContext);

	const [title, setTitle] = useState('_');
	const [recipes, setRecipes] = useState({});
	const [shops_list, setShopsList] = useState([]);
	const [rate_card, setRateCard] = useState({prices: {}});
	const [date_update, setDateUpdate] = useState(null);
	const [rev_share, setRevShare] = useState(false);
	const [override_prices, setOverridePrices] = useState(false);

	const [filter_brand, setFilterBrand] = useState([]);
	const [brands, setBrands] = useState([]);


	const [current_price_list, setCurrentPricelist] = useState({
		rev_share: false,
		filter_brand: [],
		override_prices: false
	});
	const [items, setItems] = useState([]);

	const { register, handleSubmit, errors, control, reset, setValue, watch } = useForm({
		defaultValues: current_price_list
	});

	var { id } = useParams();
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 48 * 4.5 + 8,
				width: 250,
			}
		}
	};

	useEffect(() => {

		if (Object.values(price_list).length === 0) return;
		if (Object.values(products_by_sku).length === 0) return;

		var obj = price_list[id];
		obj.date_start = obj.date_start === undefined ? 20230101 : obj.date_start;

		setDateUpdate(DateTime.fromISO(obj.date_start));
		setRateCard(GetCurrentRateCard(obj.date_start));
		setCurrentPricelist(obj);

		setTitle((id === 'new' || id === '') ? 'Nouvelle Liste de Prix' : price_list[id].name);

		var hash = {};
		var hash_brand = {};

		for (var sku in products_by_sku) {

			if (products_by_sku[sku].disabled) continue;
			if (products_by_sku[sku].sub_recipe) continue;

			var brand = (products_by_sku[sku].brand !== undefined) ? products_by_sku[sku].brand : 'Autres';
			if (hash[brand] === undefined) hash[brand] = {}

			var category = (products_by_sku[sku].category !== undefined) ? products_by_sku[sku].category : 'Autres';
			if (hash[brand][category] === undefined) hash[brand][category] = {}

			hash[brand][category][sku] = products_by_sku[sku];
			hash_brand[brand] = brand;
		};

		setBrands(Object.values(hash_brand));
		setRecipes(hash);

	}, [price_list, products_by_sku, rev_share]);


	useEffect(() => {

		if (date_update === null) return;

		setRateCard(GetCurrentRateCard(parseInt(date_update.toFormat('yyyyLLdd'))));

	}, [date_update]);


	useEffect(() => {

		if (Object.values(products_by_sku).length === 0) return;
		if (current_price_list.id === undefined) return;

		// Init Brand
		var init_brand = [];
		for (var sku in current_price_list.prices) {
			var brand = products_by_sku[sku].brand;
			if (init_brand.includes(brand) === false && current_price_list.prices[sku].enabled) init_brand.push(brand);
		}

		setRevShare(current_price_list.rev_share !== undefined ? current_price_list.rev_share : false);
		setOverridePrices(current_price_list.override_prices !== undefined ? current_price_list.override_prices : false);
		setFilterBrand(current_price_list.filter_brand !== undefined ? current_price_list.filter_brand : init_brand);

		reset(current_price_list);

	}, [current_price_list, products_by_sku]);


	useEffect(() => {

		if (current_price_list.id === undefined) return;

		GetShopsByPricelist(current_price_list.pricelist_group_id, (list) => {
			setShopsList(list);
		})

	}, [current_price_list]);


	useEffect(() => {

		if (Object.values(recipes).length === 0) return;

		const items = [];

		var sort_brand = Object.entries(recipes).sort((a, b) => {
			if (a[0].substring(2) < b[0].substring(2)) return -1;
			if (a[0].substring(2) > b[0].substring(2)) return 1;
			return 0;
		});

	 	for (var i in sort_brand) {

	 		var brand = sort_brand[i][0];
	 		if (!filter_brand.includes(brand) && (current_price_list.rate_card === undefined || current_price_list.rate_card === false)) continue;

			items.push(<Brand key={String(i)} category={brand} />);

			var sort_category = Object.entries(sort_brand[i][1]).sort();
			for (var j in sort_category) {

				items.push(<Category key={String(i) + '_' + String(j)} category={sort_category[j][0]} />);

				var sort_sku = Object.entries(sort_category[j][1]).sort();
				for (var k in sort_sku) {
					var sku = sort_sku[k][0];
					items.push(<PriceForm key={String(i) + '_' + String(j) + '_' + String(k)} register={register} control={control} rev_share={rev_share} override_prices={override_prices} pricelist={current_price_list.prices !== undefined ? current_price_list.prices[sort_sku[k][1].sku] : current_price_list} {...sort_sku[k][1]} is_rate_card={current_price_list.rate_card !== undefined ? current_price_list.rate_card : false} rate_card={rate_card} />);
				};
			};
		};

		setItems(items);

	}, [recipes, override_prices, filter_brand, current_price_list]);


	const handleChange = (event) => {

		const {
			target: { value },
		} = event;

		setFilterBrand(
			typeof value === 'string' ? value.split(',') : value,
		);
	};


	const onSubmit = (raw_data) => {

		var raw_data_stringified = JSON.stringify(raw_data);

		var data = JSON.parse(raw_data_stringified);
		data.date_start = parseInt(date_update.toFormat('yyyyLLdd'));

		data.filter_brand = filter_brand;
		//data.override_prices = override_prices;

		data.delivery_fee = (data.delivery_fee === '' || data.delivery_fee === undefined) ? 0 : parseInt(data.delivery_fee);
		data.franco = (data.franco === '' || data.franco === undefined) ? 0 : parseInt(data.franco);
		data.augmentation = (data.augmentation === '' || data.augmentation === undefined) ? 0 : parseInt(data.augmentation);
		data.percentage = (data.percentage === '' || data.percentage === undefined) ? 0 : parseFloat(data.percentage);

		for (var sku in data.prices) {

			if (data.override_prices === false) delete data.prices[sku].override_price;
			if (data.override_prices && isNaN(parseFloat(data.prices[sku].override_price))) delete data.prices[sku].override_price;
			if (data.prices[sku].override_price !== undefined) data.prices[sku].override_price = parseFloat(data.prices[sku].override_price);

			if (typeof data.prices[sku] === 'string') {
				data.prices[sku] = data.prices[sku] === '' ? 0 : parseFloat(data.prices[sku]);
				continue;
			};

			if (data.prices[sku].enabled !== true && ((data.prices[sku].retail_price !== undefined && data.prices[sku].retail_price === '') || (data.prices[sku].discount !== undefined && data.prices[sku].discount === ''))) { //|| obj.prices[sku].enabled !== true
				delete data.prices[sku];
				continue;
			};

			data.prices[sku].tva_percent = parseFloat(data.prices[sku].tva_percent);

			if (data.rev_share === true) delete data.prices[sku].discount;
			if (data.prices[sku].discount !== undefined) {
				data.prices[sku].discount = (data.prices[sku].discount === '') ? 0 : parseFloat(data.prices[sku].discount);
			};

			if (data.rev_share === false) delete data.prices[sku].retail_price;
			if (data.prices[sku].retail_price !== undefined) {
				data.prices[sku].retail_price = (data.prices[sku].retail_price === '') ? 0 : parseFloat(data.prices[sku].retail_price);
			};

			if (data.rev_share === true) delete data.prices[sku].discounts;
			if (data.prices[sku].discounts !== undefined) {
				for (var i in data.prices[sku].discounts) {
					if (data.prices[sku].discounts[i].range !== undefined) data.prices[sku].discounts[i].range = parseInt(data.prices[sku].discounts[i].range);
					if (data.prices[sku].discounts[i].discount !== undefined) data.prices[sku].discounts[i].discount = parseFloat(data.prices[sku].discounts[i].discount);
				};
			};
		};

		console.log(data);
		//if (current_price_list.id !== 'S3UtMIG24MLFoPTDzj6O') return;

		firebase.firestore().collection('shaki_pricelists').doc(current_price_list.id).update(data).then(() => {
			setOpenSnackBar('Sauvegardé')
		});
	};


	const Brand = (row) => {
		return (
			<div style={{ backgroundColor: '#000', color: '#fff', padding: 10, paddingLeft: 20, marginBottom: 20 }}>
				{row.category}
			</div>
		);
	};


	const Category = (row) => {
		return (
			<div style={{ borderBottom: '1px solid grey', padding: 10, paddingLeft: 20, marginTop: 20 }}>
				{row.category}
			</div>
		);
	};


	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>

			<div style={{ padding: 20 }}>

				<Breadcrumbs aria-label='breadcrumb'>
					<Link to='/'>Accueil</Link>
					<Link to='/prices'>Liste des Prix</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>

				<h2 style={{ marginBottom: 40 }}>{title}</h2>

				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>

					<div>

						<TextField
							required
							inputRef={register({
								required: true
							})}
							InputLabelProps={{
								shrink: true,
							}}
							name='name'
							label='Label'
							style={{ width: 250 }}
							error={errors.name ? true : false}
						/>

						<MuiPickersUtilsProvider utils={LuxonUtils}>
							<KeyboardDatePicker
								name='date_start'
								disableToolbar
								format='d MMMM yyyy'
								margin='normal'
								label="Date d'application"
								KeyboardButtonProps={{
									'aria-label': 'change date'
								}}
								value={date_update}
								onChange={(date) => {
									return setDateUpdate(date)
								}}
								style={{ width: 180, marginLeft: 10, marginTop: 0 }}
							/>
						</MuiPickersUtilsProvider>

						{current_price_list.rate_card === undefined &&
						<Controller
							inputRef={register()}
							name='rev_share'
							control={control}
							color='default'
							render={(props) =>
								<FormControlLabel
									control={<Checkbox color='default' onChange={(e) => {props.onChange(e.target.checked); setRevShare(e.target.checked) }} checked={props.value} />}
									label='Concession'
									style={{ marginLeft: 10, width: 170 }}
								/>
							}
						/>
						}

					</div>

					{current_price_list.rate_card === false || current_price_list.rate_card === undefined &&
					<div style={{ display: 'flex', paddingBottom: 15 }}>

						{rev_share ?
						<TextField
							inputRef={register({})}
							InputProps={{
								endAdornment : <InputAdornment position="start">%</InputAdornment>,
							}}
							name='percentage'
							label='Pourcentage'
							type='number'
							style={{ width: 100 }}
							InputProps={{
								inputProps: {
									min: 0,
									step: .1
								},
								endAdornment: <InputAdornment position='start'>%</InputAdornment>
							}}
						/>
						:
						<div style={{ display: 'flex', paddingBottom: 15 }}>
							<TextField
								inputRef={register({})}
								InputProps={{
									endAdornment: <InputAdornment position="start">€</InputAdornment>,
								}}
								name='delivery_fee'
								label='Coût de livraison'
								defaultValue='25'
								type='number'
								style={{ width: 100, marginRight: 10 }}
							/>
							<TextField
								inputRef={register({})}
								InputProps={{
									endAdornment: <InputAdornment position="start">€</InputAdornment>,
								}}
								name='franco'
								label='Franco'
								type='number'
								style={{ width: 100, marginRight: 10 }}
							/>
							<TextField
								inputRef={register({})}
								InputProps={{
									endAdornment: <InputAdornment position="start">%</InputAdornment>,
								}}
								name='augmentation'
								label='Majoration'
								type='number'
								style={{ width: 100 }}
							/>
						</div>
						}

					</div>
					}

				</div>

				{current_price_list.rate_card === undefined &&
				<div style={{ paddingBottom: 10 }}>
					<div>
						<b>Tarif général appliqué</b> : {rate_card.name}
					</div>
					{!rev_share &&
						<Controller
							inputRef={register()}
							name='override_prices'
							control={control}
							color='default'
							value={override_prices}
							render={(props) =>
								<FormControlLabel
									control={<Checkbox color='default' onChange={(e) => {props.onChange(e.target.checked); setOverridePrices(e.target.checked) }} checked={props.value} />}
									label='Ecrasement des tarifs généraux'
								/>
							}
						/>
					}
				</div>
				}

				{current_price_list.rate_card === false || current_price_list.rate_card === undefined &&
				<div>
					<FormControl style={{ width: "100%" }}  sx={{ m: 1, width: 300 }}>
						<InputLabel id="demo-multiple-checkbox-label">Sélectionnez les marques de cette liste de prix</InputLabel>
						<Select
							labelId="demo-multiple-checkbox-label"
							id="demo-multiple-checkbox"
							multiple
							value={filter_brand}
							onChange={handleChange}
							input={<OutlinedInput label="Sélectionnez les marques de cette liste de prix" />}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={MenuProps}
						>
						{brands.map((name) => (
						<MenuItem key={name} value={name}>
							<Checkbox checked={filter_brand.includes(name)} />
							<ListItemText primary={name} />
						</MenuItem>
						))}
						</Select>
					</FormControl>
				</div>
				}

			</div>

			<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

				<div style={{ width: '75%', paddingBottom: 80 }}>
					{items}
				</div>

				<div style={{ width: '25%', marginLeft: 10 }}>
					<div style={{ backgroundColor: '#000', color: '#fff', padding: 10, paddingLeft: 20, marginBottom: 20 }}>
						Magasins attachés
					</div>
					{shops_list.map((shop, index) => (
						<div key={shop.id}><Link to={'/shops/' + shop.id}>{shop.name}</Link></div>
					))}
				</div>

			</div>

			<div style={{ position: 'fixed', bottom: 0, left: 200, width: '100%', backgroundColor: 'white', padding: 24, borderTop: 'solid rgba(0, 0, 0, 0.12) 1px' }}>
				<Button disabled={!checkUserRights('pricelists_write')} variant="contained" color="primary" type='submit'>
					Enregistrer
				</Button>
			</div>

			</form>
		</div>
	);
}

export default React.memo(Price);

